import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Impressum`}</h1>
    <p>{`Last Updated: 02.01.2022`}</p>
    <p>{`Anes Mulalic`}</p>
    <p>{`Am Rinkenbuhl 3`}</p>
    <p>{`64807 Dieburg`}</p>
    <p>{`Germany`}</p>
    <h2>{`Disclaimer`}</h2>
    <p>{`This document contains the information required for an Impressum.`}</p>
    <p>{`For more information about how your data is used, collected, and stored, please read the `}<a href="/privacy" className="legal-paragraph--red">{`privacy policy`}</a>{`.`}</p>
    <h2>{`Content`}</h2>
    <p>{`All the content on this website - `}<span className="legal-links"><a parentName="p" {...{
          "href": "http://www.anesmulalic.com"
        }}>{`www.anesmulalic.com`}</a></span>{` - is published in good faith and for general information purpose only.`}</p>
    <p>{`The author does not make representation or warranties of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on this website.`}</p>
    <p>{`Any action you take upon the information you find on this website (`}<span className="legal-links"><a parentName="p" {...{
          "href": "http://www.anesmulalic.com"
        }}>{`www.anesmulalic.com`}</a></span>{`), is strictly at your own risk.`}</p>
    <p>{`The author will not be liable for any losses and/or damages in connection with the use of our website.`}</p>
    <p>{`Parts of the pages or the complete publication including all offers and information might be extended, changed or partly or completely deleted by the author without separate announcement.`}</p>
    <h2>{`Links + Referrals`}</h2>
    <p>{`This website may contain (or you may be sent through the website) links to other websites or content belonging to or originating from third parties or links to websites and features.`}</p>
    <p>{`While I strive to provide only quality links to useful and ethical websites, I have no control over the content and nature of these sites.`}</p>
    <p>{`These links to other websites do not imply a recommendation for all the content found on these sites.
Site owners and content may change without notice and may occur before we have the opportunity to remove a link which may have gone 'bad'.`}</p>
    <p>{`Please be also aware that when you leave our website, other sites may have different privacy policies and terms which are beyond my control.`}</p>
    <p>{`Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before engaging in any business or uploading any information.`}</p>
    <h2>{`Consent`}</h2>
    <p>{`By using our website, you hereby consent to our disclaimer and agree to its terms.`}</p>
    <h2>{`Errors and Omissions`}</h2>
    <p>{`While I have made every attempt to ensure that the information contained in this site has been obtained from reliable sources, `}<span className="legal-links"><a parentName="p" {...{
          "href": "http://www.anesmulalic.com"
        }}>{`www.anesmulalic.com`}</a></span>{` is not responsible for any errors or omissions or for the results obtained from the use of this information.`}</p>
    <p>{`All information in this site is provided „as is“, with no guarantee of completeness, accuracy, timeliness or of the the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability, and fitness for a particular purpose.`}</p>
    <h2>{`Update and Changes`}</h2>
    <p>{`Should we update, amend or make any changes to this document, those changes will be prominently posted here.`}</p>
    <h2>{`Contact`}</h2>
    <p>{`Do you have any feedback, comments, requests for technical support or other inquiries, feel free to contact me by email:`}</p>
    <span className="legal-links">hello@anesmulalic.com</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      